import revive_payload_client_OQnxu88BtL from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__idb-keyval@6._72mlpnksjjaib4hd7scewhafbu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pSQgcXecDv from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__idb-keyval@6._72mlpnksjjaib4hd7scewhafbu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yXlY7bt29L from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__idb-keyval@6._72mlpnksjjaib4hd7scewhafbu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9aPWPnPakk from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__idb-keyval@6._72mlpnksjjaib4hd7scewhafbu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qmjfuNehxd from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__idb-keyval@6._72mlpnksjjaib4hd7scewhafbu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hFWAeBRtmi from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__idb-keyval@6._72mlpnksjjaib4hd7scewhafbu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_SQiLMpXoFr from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__idb-keyval@6._72mlpnksjjaib4hd7scewhafbu/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_GxOXJF4Xjc from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__idb-keyval@6._72mlpnksjjaib4hd7scewhafbu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_VgJHvYbciu from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__idb-keyval@6._72mlpnksjjaib4hd7scewhafbu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ggqsX8yHSG from "/app/node_modules/.pnpm/nuxt-bugsnag@7.4.0-beta.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.22.0_vue-router@4.4.5__gw6j5t4mjlv5qi7u4kcvxnlqoe/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_client_LgtWtE4TTt from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.22.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_TZhnuTP3jx from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.3_@parcel+watcher@2.4.1_@types+node@22.5.5_h3@1.12.0_idb-keyval@6.2.1_iore_wrstf6bk4vgdtlgoy5bi4azf2y/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_OVIkYZidp5 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.22.0_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_kViXmKmZV1 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.22.0_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_59dV7Il1ZP from "/app/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.22.0_vite@5.4.6_@types+node@22.5.5_terser@5.33.0__vu_cfuuuczuqb7ljbjklqqzl7rgii/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import posthog_client_fUucxKWhZE from "/app/plugins/posthog.client.ts";
export default [
  revive_payload_client_OQnxu88BtL,
  unhead_pSQgcXecDv,
  router_yXlY7bt29L,
  payload_client_9aPWPnPakk,
  navigation_repaint_client_qmjfuNehxd,
  check_outdated_build_client_hFWAeBRtmi,
  view_transitions_client_SQiLMpXoFr,
  chunk_reload_client_GxOXJF4Xjc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_VgJHvYbciu,
  plugin_ggqsX8yHSG,
  plugin_client_LgtWtE4TTt,
  plugin_TZhnuTP3jx,
  switch_locale_path_ssr_OVIkYZidp5,
  i18n_kViXmKmZV1,
  plugin_59dV7Il1ZP,
  posthog_client_fUucxKWhZE
]